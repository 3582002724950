import React, { useContext, useState } from 'react';
import pirmLogo from '../../images/pirmLogo.png';
import css from './style.module.css';
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from '../../lib/firebase'; // Ensure this path is correct
import { useNavigate } from "react-router-dom";
import { vendorLoginContext } from '../../Context/vendorLogin';
import axios from 'axios';
import { VendoContext } from '../../Context/vendorContext';
import { SalesforceContext } from '../../Context/SF.CredantialsContext';

export default function VendorLogin() {
    const { setUserData } = useContext(vendorLoginContext);
    const { setTokens } = useContext(SalesforceContext);
    const { setEmail } = useContext(VendoContext);
    const [data, setData] = useState({ email: '', password: '' });
    const [spinner, setSpinner] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();
    // get the data from input
    const getData = (e) => {
        const { name, value } = e.target;
        setData((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const getCompanyCredentials = async (body) => {
        try {
            const response = await axios.post(
                `https://us-central1-policy-portal-4ca94.cloudfunctions.net/credentialsFunc/companies/email`, body
            );

            const data = await axios.get(
                `https://us-central1-policy-portal-4ca94.cloudfunctions.net/credentialsFunc/credentials`,
                {
                    headers: {
                        Authorization: `Bearer ${response.data.data}`,
                    },
                }
            );

            return { data: data.data.data, token: response.data.data };
        } catch (error) {
            console.error('Error fetching credentials:', error);
            throw error;
        }
    };

    const checkCredentials = async (body) => {
        setSpinner(true);  // Show spinner before making the request
        setError('');
        try {
            const { data, token } = await getCompanyCredentials(body);
            // console.log(token);
            setTokens(token)
            localStorage.setItem('Company Logo', data.logo);
            localStorage.setItem('Token', JSON.stringify(token));
            return true
        } catch (error) {
            if (error.response && error.response.status === 404) {
                setError(error.response.data.message);
            } else {
                setError("An error occurred while fetching credentials.");
            }
        } finally {
            setSpinner(false);
        }
    };

    const login = async () => {
        setError('');
        setSpinner(true);
        try {
            const { email, password } = data;
            const res = await signInWithEmailAndPassword(auth, email, password);
            setUserData(res)
            const user = res.user;
            setEmail(user.email);
            localStorage.setItem("Vendor User", user.uid);
            localStorage.setItem("User Email", email);
            const Name = user.displayName ? user.displayName.split(',') : [];
            localStorage.setItem("User Name", Name[0].trim());
            localStorage.setItem("CompanyNam Email", Name[1].trim());

            const body = { companyName: Name[1] ? Name[1].trim().toLowerCase() : "" };
            if (await checkCredentials(body)) {
                navigate('/vendors');
            }


        } catch (error) {
            console.error('Error logging in:', error);
            setError(error.message);
        } finally {
            setSpinner(false);
        }
    };


    return (
        <div className="d-flex justify-content-center align-items-center min-vh-100">
            <div className="border border-2 rounded documents">
                <header className="text-primary py-3 fw-bold px-5 mt-2 border-bottom border-2 rounded-top text-center">
                    <img src={pirmLogo} alt="Company logo" className={css.logo} />
                </header>
                <main className="py-3 text-center px-4">
                    <p className="text-center fw-bold mt-4 mb-2" style={{ fontWeight: 400, fontSize: "20px" }}>
                        Compliance Portal
                    </p>

                    <label htmlFor="email" className='d-flex justify-start mb-2 ms-2'>Email</label>
                    <input
                        type="email"
                        name='email'
                        id='email'
                        className='form-control me-3 mb-3 inputStyle'
                        onChange={getData}
                        placeholder='example@example.com'
                    />
                    <label htmlFor="password" className='d-flex justify-start mb-2 ms-2'>Password</label>
                    <input
                        type="password"
                        id='password'
                        name='password'
                        className='form-control inputStyle'
                        onChange={getData}
                        placeholder='password'
                    />
                    <button
                        onClick={login}
                        className="btn text-white my-4 mx-3 px-5 py-2 rounded-1"
                        style={{ backgroundColor: "#2268DE" }}
                        disabled={spinner} // Disable button when spinner is active
                    >
                        Login
                        {spinner && <i className="ms-3 fas fa-spinner fa-pulse"></i>}
                    </button>
                    {error && <div className="alert alert-danger">{error}</div>} {/* Error message display */}
                </main>
            </div>
        </div>
    );
}
