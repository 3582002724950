import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { VendoContext } from '../../Context/vendorContext.js';
import SkeletonVendorPage from '../LoadingPages/SkeletonVendorPage.jsx';

export default function VendorsTable({ pirm_addon__Status__c = "Pending" }) {
  const { Vendors = [], message } = useContext(VendoContext);
  const [vendor, setVendor] = useState([]);

  useEffect(() => {
    if (Array.isArray(Vendors)) {
      const filteredVendors = Vendors.filter(vendor =>
        pirm_addon__Status__c === "Completed" ? vendor.pirm_addon__Status__c === "Completed" : vendor.pirm_addon__Status__c !== "Completed"
      );
      setVendor(filteredVendors);
    }
  }, [Vendors, pirm_addon__Status__c, message]);

  if (!vendor.length && message === "success") {
    return <p className="ms-2">No Data Added Yet!</p>;
  }
  if (!vendor.length && message === null) {
    return <SkeletonVendorPage />;
  }
  if (!vendor.length && message === "No data") {
    return <p className="ms-2">No Data Added Yet!</p>;
  }

  const formatDate = (date) => date ? date.split("T")[0].split("-").slice(1).join("/") : " ";

  return (
    <table className="table-bordered documents rounded-table ms-1 p-0" style={{ marginTop: "30px" }}>
      <thead>
        <tr>
          <th scope="col" className="py-1 px-2">#</th>
          <th scope="col" className="py-1 px-2">Name</th>
          <th scope="col" className="py-1 px-2">Type</th>
          <th scope="col" className="py-1 px-2">Date Assigned</th>
          <th scope="col" className="py-1 px-2">Due Date</th>
        </tr>
      </thead>
      <tbody>
        {vendor.map((item, index) => (
          <tr key={item.Id}>
            <th scope="row" className="py-1 px-2 border">{index + 1}</th>
            <td className="py-1 px-2">
              <Link
                to={`/questionnaires`}
                state={{ id: item.Id, pirm_addon__Status__c, Audits_r: item.pirm_addon__Audits__r?.records[0]?.Id }}
                className="text-decoration-none text-dark"
              >
                {item.Name}
              </Link>
            </td>
            <td className="py-1 px-2">Questionnaire</td>
            <td className="py-1 px-2">{formatDate(item.CreatedDate)}</td>
            <td className="py-1 px-2">{formatDate(item.pirm_addon__Due_Date__c)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
