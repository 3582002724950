import axios from "axios";
import { createContext, useEffect, useState } from "react";

// Create the Salesforce context
export const SalesforceContext = createContext(null);

export default function SalesforceProvider({ children }) {
  const [tokens, setTokens] = useState(() => {
    // Initialize state from localStorage
    const storedCredentials = localStorage.getItem("Token");
    return storedCredentials ? JSON.parse(storedCredentials) : null; // Set to null if no token
  });
  const [salesforce, setSalesforce] = useState({});

  useEffect(() => {
    const getData = async () => {
      if (tokens !== null) {
        try {
          const { data } = await axios.get(
            `//us-central1-policy-portal-4ca94.cloudfunctions.net/credentialsFunc/credentials`,
            {
              headers: {
                Authorization: `Bearer ${ tokens}`,
              },
            }
          );
          // Ensure you access data correctly
          setSalesforce({
            sfUsername: data.data.sfUsername,
            sfPassword: data.data.sfPassword,
            sfSecurityToken: data.data.sfSecurityToken,
          });
        } catch (error) {
          console.error("Error fetching credentials:", error);
          // Handle the error as needed (e.g., set an error state)
        }
      }
    };
    getData();
  }, [tokens]);

  return (
    <SalesforceContext.Provider value={{ setTokens, salesforce }}>
      {children}
    </SalesforceContext.Provider>
  );
}
