import React, { createContext, useState, useContext, useEffect } from "react";
import OktaAuth from "@okta/okta-auth-js";

const OktaConfigContext = createContext();

export const useOktaConfig = () => useContext(OktaConfigContext);

export const OktaConfigProvider = ({ children }) => {
  const [oktaConfig, setOktaConfig] = useState(() => {
    // Initialize with localStorage to prevent setting state within the effect.
    const storedCredentials = localStorage.getItem("credentials");
    return storedCredentials ? JSON.parse(storedCredentials) : {};
  });

  const [oktaAuths, setOktaAuths] = useState(null);

  useEffect(() => {
    if (oktaConfig.clientId && oktaConfig.issuer) {
      const authClient = new OktaAuth(oktaConfig);
      // Store credentials in localStorage only if they change meaningfully.
      if (!localStorage.getItem("credentials") || localStorage.getItem("credentials") !== JSON.stringify(oktaConfig)) {
        localStorage.setItem("credentials", JSON.stringify(oktaConfig));
      }
      setOktaAuths(authClient);
    }
  }, [oktaConfig]);

  // Return a context value even if oktaAuths is null to prevent undefined errors
  const value = { oktaAuths, setOktaConfig };

  return (
    <OktaConfigContext.Provider value={value}>
      {children}
    </OktaConfigContext.Provider>
  );
};
