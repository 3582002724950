import React, { useState, useEffect, useContext } from 'react';
import pirmLogo from '../../images/pirmLogo.png';
import css from './style.module.css';
import { useNavigate } from 'react-router-dom';
import { useOktaConfig } from '../../Context/CredentialsContext';
import Joi from 'joi';
import axios from 'axios';
export default function Domain() {
    const REACT_APP_PORT = 'http://localhost:3000/login/callback';
    const REACT_APP_REDIRECT_URL = 'https://policy-portal-4ca94.web.app/login/callback';
    const lendistry = 'https://lendistry.pirm.io/login/callback';

    const getRedirectUri = () => {
        const currentHost = window.location.host;
        if (currentHost.includes('localhost')) {
            return REACT_APP_PORT;
        } else if (currentHost.includes('policy-portal-4ca94.web.app')) {
            return REACT_APP_REDIRECT_URL;
        } else if (currentHost.includes('lendistry.pirm.io')) {
            return lendistry;
        }
        // Default to one of the URIs if none match (or throw an error)
        return REACT_APP_REDIRECT_URL;
    };

    const Schema = Joi.object({
        domain: Joi.string().pattern(/^[a-zA-Z0-9_-]+\.okta\.com$/).messages({
            "string.pattern.base": "Domain must be a valid Okta domain",
            "string.empty": "Domain is required",
            "any.required": "Domain is required",
        }).required()
    })

    const navigate = useNavigate();
    const { setOktaConfig } = useOktaConfig();

    const [error, setError] = useState(null);
    const [spinner, setSpinner] = useState(false);
    const [domainResult, setDomainResult] = useState({});
    const [domain, setDomain] = useState('');

    const getText = (e) => {
        setDomain(e.target.value);
    };

    const getCompanyCredentials = async (domain) => {
        try {
          
            const response = await axios.get(
                `https://us-central1-policy-portal-4ca94.cloudfunctions.net/credentialsFunc/companies/${domain}`
            );

          
            const data = await axios.get(
                `https://us-central1-policy-portal-4ca94.cloudfunctions.net/credentialsFunc/credentials`,
                {
                    headers: {
                        Authorization: `Bearer ${response.data.data}`,
                    },
                }
            );

            return {data:data.data.data,token:response.data.data};
        } catch (error) {
            console.error('Error fetching credentials:', error);
            throw error; 
        }
    };

    const checkCredentials = async () => {
        const { error } = Schema.validate({ domain }, { abortEarly: false });

        if (error) {
            setError(error.details[0].message);
            setSpinner(false);  // Hide spinner if validation fails
            return;
        }

        setError(null);
        setSpinner(true);  // Show spinner before making the request

        try {
            const {data,token} = await getCompanyCredentials(domain);

            // Optionally handle credentialsData here if needed
            localStorage.setItem('Company Logo', data.logo);
            localStorage.setItem('Token', JSON.stringify(token));
            
 
            setOktaConfig({
                clientId: data.oktaClientID,
                issuer: `https://${data.oktaDomain}/oauth2/default`,
                redirectUri: getRedirectUri(),
                scopes: ["openid", "profile", "email"],
                pkce: true,
                disableHttpsCheck: true,
            });
            
        } catch (error) {
            if (error.response && error.response.status === 404) {
                setError("Credentials not found for this domain.");
            } else {
                setError("Error checking domain credentials.");
            }
            setDomainResult({});
        } finally {
            setSpinner(false);  
        }
    };


    // Check if oktaAuth is ready and navigate when it's set
    useEffect(() => {
        if (domainResult.oktaClientID) {
            navigate("/");
        }
    }, [domainResult, navigate]);

    return (
        <div className="d-flex justify-content-center align-items-center min-vh-100">
            <div className="border border-2 rounded documents">
                <header className="text-primary py-3 fw-bold px-5 mt-2 border-bottom border-2 rounded-top text-center">
                    <img src={pirmLogo} alt="Company logo" className={css.logo} />
                </header>
                <main className="py-3 text-center px-4">
                    <p className="text-center fw-bold mt-4 mb-2" style={{ fontWeight: 400, fontSize: "20px" }}>
                        Compliance Portal
                    </p>
                    <input
                        onChange={getText}
                        type="text"
                        className="form-control text-center"
                        placeholder="Enter your company domain"
                    />
                    {error && <p className="text-danger fw-bold mt-2 mb-0">{error}</p>}
                    <button
                        onClick={checkCredentials}
                        className="btn text-white my-4 mx-3 rounded-1"
                        style={{ backgroundColor: "#2268DE" }}
                        disabled={spinner}
                    >
                        Enter the company domain
                        {spinner && <i className="ms-3 fas fa-spinner fa-pulse"></i>}
                    </button>
                </main>
            </div>
        </div>
    );
}
