import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { SalesforceContext } from "./SF.CredantialsContext";

export const VendoContext = createContext();

export default function VendorProvider({ children }) {
  const { salesforce } = useContext(SalesforceContext);
  const [Vendors, setVendors] = useState([]);
  const [message, setMessage] = useState(null);
  const [email, setEmail] = useState(() => {
    // Initialize state from localStorage
    const storedEmail = localStorage.getItem("User Email");
    return storedEmail || null; // Use logical OR for cleaner code
  });

  useEffect(() => {
    if (Object.keys(salesforce).length && email) {

      getVendors(salesforce, email);
    }
  }, [salesforce, email]);

  const getVendors = async (salesforce, email) => {
    try {
      const response = await axios.post("https://api-skhwrpqgyq-uc.a.run.app/vendorlist", {
        ...salesforce,
        email,
      });

      setMessage(response.data.message);
      setVendors(response.data.data);
    } catch (error) {
      const data = error.response ? error.response.data : { message: "Network error" };
      setMessage(data.message);
      setVendors([]);
      console.error("Error fetching vendors:", error.message);
    }
  };

  const getVendorQuestionnaire = async (id) => {
    try {
      const response = await axios.post(
        `https://api-skhwrpqgyq-uc.a.run.app/questionnaire/${id}`,
        salesforce
      );

      const finalResult = response.data.data;

      finalResult.sort((a, b) => {
        const aParentTemplate =
          a.pirm_addon__Question__r?.pirm_addon__Template__r?.pirm_addon__Parent_Template__c;
        const bParentTemplate =
          b.pirm_addon__Question__r?.pirm_addon__Template__r?.pirm_addon__Parent_Template__c;

        if (aParentTemplate == null && bParentTemplate != null) return -1;
        if (aParentTemplate != null && bParentTemplate == null) return 1;
        return 0;
      });

      return finalResult;
    } catch (error) {
      console.error("Error fetching questionnaire:", error.message);
    }
  };

  return (
    <VendoContext.Provider
      value={{ Vendors, message, setEmail, getVendorQuestionnaire }}
    >
      {children}
    </VendoContext.Provider>
  );
}
