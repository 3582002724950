import React, { useContext, useEffect, useState } from "react";
import css from "./style.module.css";
import axios from "axios";
import { SalesforceContext } from "../../Context/SF.CredantialsContext";
import SkeletonQuestionsPage from "../LoadingPages/SkeletonQuestionsPage";


export default function Questions({ questionnaire, position, id, Audits_r, pirm_addon__Status__c, allVendorAnswer, setAllVendorAnswer }) {
    const [data, setData] = useState([]); // Stores the questionnaire data
    const [fileName, setFileName] = useState({}); // Tracks uploaded file names
    const [filesBase64, setFilesBase64] = useState({}); // Tracks uploaded files with Base64 content
    const [saveSpinner, setSaveSpinner] = useState(false);
    const [updateSpinner, setUpdateSpinner] = useState(false);
    const [buttonClicked, setButtonClicked] = useState(false);
    const [visible, setVisible] = useState(false);
    const [error, setError] = useState('');
    const { salesforce } = useContext(SalesforceContext);
    const sfUsername = salesforce.sfUsername;
    const sfPassword = salesforce.sfPassword;
    const sfSecurityToken = salesforce.sfSecurityToken;

    const [isActive, setIsActive] = useState(false);
    const [isVisible, setIsVisible] = useState(false);

    // useEffect(() => {
    //     console.log("all data :");
    //     console.log(data);
    // }, [data]);


    useEffect(() => {
        if (questionnaire && questionnaire[position]) {
            const clonedData = JSON.parse(JSON.stringify(questionnaire[position])); // Deep clone to avoid mutating props
            setData(clonedData);

        }
    }, [questionnaire, position]);


    if (!data.length) return <SkeletonQuestionsPage />;


    const openModal = () => {
        setIsVisible(true); // Show the modal
        setTimeout(() => setIsActive(true), 10); // Add active class after a slight delay
    };

    const closeModal = () => {
        setIsActive(false); // Remove active class
        setTimeout(() => setIsVisible(false), 500); // Hide modal after transition
    };

    // file
    const handleFileChange = (event, questionId) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const base64String = reader.result.split(',')[1];


                setFilesBase64((prev) => ({
                    ...prev,
                    [questionId]: base64String,
                }));
                setFileName((prev) => ({
                    ...prev,
                    [questionId]: file.name,
                }));
            };
            reader.onerror = () => {
                console.error("error to send file .");
                setFileName((prev) => ({
                    ...prev,
                    [questionId]: "error to send file ",
                }));
            };
        } else {
            setFilesBase64((prev) => ({
                ...prev,
                [questionId]: null,
            }));
            setFileName((prev) => ({
                ...prev,
                [questionId]: "No file chosen",
            }));
        }
    };
    // pickList
    const handlePicklistChange = (questionIndex, selectedValue) => {
        setData((prevData) => {
            const updatedData = [...prevData];
            updatedData[questionIndex].pirm_addon__Answer__c = selectedValue;
            return updatedData;
        });
    };
    // text
    const handleTextChange = (questionIndex, text) => {
        setData((prevData) => {
            const updatedData = [...prevData];
            updatedData[questionIndex].pirm_addon__Answer__c = text;
            return updatedData;
        });
    };
    // update all answer array of questions
    const updateAllAnswer = () => {
        const wasAnswered = data.filter((e) => (
            e.pirm_addon__QuestionType__c !== "Blocker"
        )).map((e) => (
            e.pirm_addon__QuestionType__c === "Document Upload" ?
                Object.keys(fileName).length !== 0 ?
                    {
                        Id: e.Id,
                        pirm_addon__Answer__c: e.ContentDocumentLinks?.totalSize + 1 || null
                    } : {
                        Id: e.Id,
                        pirm_addon__Answer__c: e.ContentDocumentLinks?.totalSize || null
                    } :
                {
                    Id: e.Id,
                    pirm_addon__Answer__c: e.pirm_addon__Answer__c
                }
        ));
        setAllVendorAnswer(allVendorAnswer.map(obj1 => {
            const matchingObj = wasAnswered.find(obj2 => obj2.Id === obj1.Id);
            return matchingObj ? { ...obj1, pirm_addon__Answer__c: matchingObj.pirm_addon__Answer__c } : obj1;
        }))
    }
    // is there no answerd questions? 
    const checkForUnansweredQuestions = () => {
        updateAllAnswer()
        return allVendorAnswer.some(e => e.pirm_addon__Answer__c === null || e.pirm_addon__Answer__c === 'null');
    };
    //    appear the message if the question not all answerd
    const handleSubmitClick = () => {
        if (checkForUnansweredQuestions()) {
            setVisible(true);
            setButtonClicked(false)
        } else {
            submitAnswers("updated");
            openModal();
        }
    };
    // submit & save  
    const submitAnswers = async (action) => {
        if (action === "updated") setUpdateSpinner(true);
        else setSaveSpinner(true);

        const updatesArray = data.map((item) => ({
            recordId: item.Id, // Audit Response ID
            newAnswer: item.pirm_addon__Answer__c,
        }));

        updateAllAnswer();
        const payload = {
            sfUsername,
            sfPassword,
            sfSecurityToken,
            newAnswer: updatesArray,
            files: [],
        };

        Object.keys(filesBase64).forEach((questionId) => {
            const fileInput = document.getElementById(questionId);
            const base64Content = filesBase64[questionId];
            if (base64Content) {
                const filePosInUpdatesArray = fileInput.id.substr(fileInput.id.length - 1);


                payload.files.push({
                    recordId: updatesArray[filePosInUpdatesArray].recordId,
                    fileName: fileName[questionId],
                    fileContent: base64Content,
                });
            }
        });
        try {
            await axios.post(
                `https://api-skhwrpqgyq-uc.a.run.app/UpdateAnswer/${id}/${Audits_r}?action=${action}`,
                payload,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );
            if (action === "updated") setUpdateSpinner(false);
            else setSaveSpinner(false);
            action === "updated" && setButtonClicked(true);

        } catch (error) {
            console.error("Error updating answers or uploading files:", error.message);
            setError(error.response?.data?.message || "error happend  ");
            setSaveSpinner(false);
            setUpdateSpinner(false);
        }
    };
    // delete files
    const deleteFile = async (docId, questionIndex) => {
        try {
            const sfCredentials = { sfUsername, sfPassword, sfSecurityToken };
            const { data } = await axios.delete(`https://api-skhwrpqgyq-uc.a.run.app/delete/file/${docId}`, {
                data: sfCredentials
            });

            // Update state after deletion
            setData((prevData) => {
                return prevData.map((item, idx) => {
                    if (idx === questionIndex) {
                        // Set answer to null when file is deleted
                        item.pirm_addon__Answer__c = null;
                    }

                    if (item.ContentDocumentLinks && item.ContentDocumentLinks.records) {
                        item.ContentDocumentLinks.records = item.ContentDocumentLinks.records.filter(
                            (file) => file.ContentDocument.Id !== docId
                        );
                    }

                    return item;
                });
            });

            return data;
        } catch (error) {

            setError(error.message);
            throw error;
        }
    };

    // function check if the question has the related question or not and also check is same answered to appear or not
    const testFunction = (Question) => {
        const relatedQuestionId = Question.pirm_addon__Question__r.pirm_addon__Related_Question__c;
        if (relatedQuestionId === null) {
            return Question;
        } else {
            const mainQuestion = data.filter((e) => e.pirm_addon__Question__c === relatedQuestionId);
            const matched = JSON.parse(Question.pirm_addon__Question__r.pirm_addon__Related_Answers__c)[0] === mainQuestion[0].pirm_addon__Answer__c
            if (matched) {
                return Question;
            }
        }
    };


    return (
        <div className="ps-5">
            {data.map((e, index) => {
                const fileUploadId = `FileUpload_${position}${index}`;
                const freeTextId = `Free_Text_${position}${index}`;
                const picklistId = `Picklist_${position}${index}`;
                const testedData = testFunction(e)
                if (testedData?.pirm_addon__QuestionType__c === "Picklist") {
                    return (
                        <div key={index} className="d-flex my-4">
                            <p className=" m-0 me-1">{index+1}-</p>
                            <div>
                                <p className=" fs-6 m-0"> {testedData.pirm_addon__Question_Text__c || " "}</p>
                                {JSON.parse(testedData.pirm_addon__Question__r.pirm_addon__Possible_Responses__c).map((response, idx) => (
                                    <label className="custom-checkbox d-block mt-2" key={idx}>
                                        <input
                                            name={`${picklistId}`}
                                            type="radio"
                                            checked={testedData.pirm_addon__Answer__c === response.name}
                                            onChange={() => handlePicklistChange(index, response.name)
                                            }
                                        />
                                        <span className="checkmark"></span>
                                        <span className="input-text-color">{response.name}</span>
                                    </label>
                                ))}
                            </div>
                        </div>
                    );
                } else if (testedData?.pirm_addon__QuestionType__c === "Document Upload") {
                    return (
                        <div key={index} className="mb-3 d-flex my-4 ">
                            <p className=" m-0 me-1 ">{index+1}-</p>
                            <div>
                                <p className=" fs-6">{testedData.pirm_addon__Question_Text__c || " "}</p>
                                <div className="d-flex mt-2 ">
                                    <input
                                        type="file"
                                        id={fileUploadId}
                                        onChange={(event) => handleFileChange(event, fileUploadId)}
                                        style={{ display: "none" }}
                                    />
                                    {pirm_addon__Status__c !== "Completed" && <>
                                        <label htmlFor={fileUploadId} className={`btn ${css.btnFiles}`}>
                                            Choose File
                                        </label>
                                        <p className="my-auto ms-2">{fileName[fileUploadId] || "No file chosen"}</p>
                                    </>}

                                </div>

                                {e.ContentDocumentLinks?.records?.length > 0 ? (
                                    e.ContentDocumentLinks.records.map((file, idx) => (
                                        <div key={idx} className=" mt-2">
                                            {pirm_addon__Status__c !== "Completed" && <i
                                                className={`fa-solid fa-trash fa-fw me-2 holdCursor ${css.trash}`}
                                                onClick={() => deleteFile(file.ContentDocument.Id, index)}
                                            ></i>
                                            }
                                            {file.ContentDocument.Title}
                                        </div>
                                    ))
                                ) : (
                                    <p>No files available for this question.</p>
                                )}
                            </div>
                        </div>
                    );
                } else if (testedData?.pirm_addon__QuestionType__c === "Free Text") {
                    return (
                        <div key={index} className="d-flex my-4">
                            <p className="m-0 me-1">{index+1}-</p>
                            <div className=" " style={{ width: "93%" }}>
                                <p className="fs-6">{testedData.pirm_addon__Question_Text__c || " "}</p>
                                <input
                                    type="text"
                                    name={freeTextId}
                                    className={`${css.inputText} border-0 w-100 `}
                                    placeholder="Enter Your Answer"
                                    value={e.pirm_addon__Answer__c || ""}
                                    onChange={(e) => handleTextChange(index, e.target.value)}
                                />
                            </div>
                        </div>
                    );
                } else {
                    return <div key={index} className="text-danger fw-bold">{testedData?.pirm_addon__Question_Text__c}</div>;
                }
            })}
            {pirm_addon__Status__c !== "Completed" && (
                <>
                    {
                        !buttonClicked && (
                            <>
                                <button onClick={() => submitAnswers("not Updated")} className="btn btn-primary mt-3 me-3">Save Answers
                                    {saveSpinner && <i className="ms-3 fas fa-spinner fa-pulse"></i>}
                                </button>
                                <button
                                    onClick={async () => {
                                        setButtonClicked(true);
                                        handleSubmitClick();

                                    }}
                                    className="btn btn-success mt-3"
                                >
                                    Submit Answers
                                    {updateSpinner && <i className="ms-3 fas fa-spinner fa-pulse"></i>}
                                </button>

                                <p className='fw-semibold'>Total Answered Questions:  {allVendorAnswer.filter((e) => (
                                    e.pirm_addon__Answer__c !== null && e.pirm_addon__Answer__c !== 'null'
                                )).length} / {allVendorAnswer.length} </p>

                                {/* Message */}
                                {visible && <div className="bg-light-black position-fixed top-0 start-0 bottom-0 end-0  ">
                                    <div className="bg-white px-5 rounded-4 position-absolute top-50 start-50 translate-middle text-center">
                                        <p className=" text-dark fw-semibold my-5 mb-4">
                                            You still have {allVendorAnswer.filter((e) => (
                                                e.pirm_addon__Answer__c === null || e.pirm_addon__Answer__c === 'null'
                                            )).length} unanswered questions. Once you submit this questionnaire you will not be able to make any additional updates. Are you sure you want to submit?</p>
                                        <button className="btn btn-danger me-3 mb-4" onClick={() => {
                                            setVisible(false);
                                        }}>Cancell</button>
                                        <button className="btn btn-success mb-4" onClick={() => {
                                            setButtonClicked(true);
                                            submitAnswers("updated");
                                            openModal();
                                        }}>Submit</button>
                                    </div>
                                </div>}
                                {/*  */}
                                {error && <p className="text-danger">{error}</p>}

                            </>
                        )
                    }
                </>
            )}
            {isVisible && (
                <div className="modal-overlay">
                    <div className={`model-prop ${isActive ? 'active' : ''}`}>
                        <div className="model-heading">
                            <h3 className="m-0">Finished </h3>
                        </div>
                        <div className="model-body">
                            <p>
                                Thank you for completing the questionnaire.
                                <br />
                                It has been submitted to the Vendor Management team for review.
                            </p>
                        </div>
                        <div className="model-footer">
                            <button className="btn btn-primary px-4 py-2" onClick={closeModal}>
                                Done
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}