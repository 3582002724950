import axios from "axios";
import { createContext, useEffect, useState, useContext } from "react";
import { SalesforceContext } from "./SF.CredantialsContext";

export const PoliciesContext = createContext([]);

export default function PoliciesProvider({ children }) {
  const { salesforce } = useContext(SalesforceContext);

  const [policies, setPolicies] = useState([]);

  useEffect(() => {
    if (Object.keys(salesforce).length) {
      getPolicies(salesforce);
    }
  }, [salesforce]);

  const getPolicies = async (salesforce) => {
    try {

      const { data } = await axios.post(
        "https://api-skhwrpqgyq-uc.a.run.app/polices",
        salesforce
      );
    


      setPolicies(data);
    } catch (error) {
      console.error("Error fetching policies:", error.message);
    }
  };

  return (
    <PoliciesContext.Provider value={{ policies, getPolicies }}>
      {children}
    </PoliciesContext.Provider>
  );
}
