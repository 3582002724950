import React, { useContext, useEffect, useState } from 'react';
import PDF from './PDF/PDF';
// import Table from './Table/Table';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import NavBar from '../NavBar/NavBar';
import { SalesforceContext } from "../../Context/SF.CredantialsContext";


export default function PoliciesDetails() {
    const { name, id } = useParams();
    const [details, setDetails] = useState([]);
    const { salesforce } = useContext(SalesforceContext);
    const getData = async (salesforce) => {
        try {
            const { data } = await axios.post(`https://api-skhwrpqgyq-uc.a.run.app/polices/${id}`, salesforce);
            setDetails(data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    useEffect(() => {
        if (Object.keys(salesforce).length) {
            getData(salesforce);
        }
    }, [salesforce]);
    return (
        <>
            <div className='overflow-hidden' style={{ backgroundColor: '#F9F3FA', paddingTop: "0px" }}>
                <div className="row p-0 m-0">
                    <div className="col-md-1">
                        <NavBar />
                    </div>
                    <div className="col-md-11">
                        <div className="container-fluid pt-3 mb-3">
                            <div className='mb-3 rounded-2 d-flex documents' style={{ border: '1px solid #D8D6D6', borderRadius: "25px" }}>
                                <div className='pb-1'>
                                    <h5 className='m-0 py-2 ms-3' style={{ fontWeight: "600" }}>
                                        {name}
                                    </h5>
                                </div>
                            </div>
                            <div className="vh-100">
                                <PDF name={name} details={details} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

};